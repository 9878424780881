import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import Select from 'react-select';
import styled from 'styled-components';
import { AdmitButton3, AdmitStudentRole, FormInputStudent, FormLable } from '../../data/Profile';
import { colors } from '../../data/Colors';
import { BudgetGrid, contextMenuItems, countryList, customers, emailData, emailGrid, paymentData, paymentGrid, paymentMethod, paymentReference, PriceConfigGrid, PricePerDayGrid, pricingType, products, SPandLGrid } from '../../data/champion';
import { GridComponent, ContextMenu, Edit, ExcelExport, Filter, Page, PdfExport, Resize, Sort, ColumnDirective, ColumnsDirective, Inject } from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import Selector from '../../data/Selector';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { apiServer } from '../../data/Endpoint';
import { Show } from '../../data/Alerts';
import { Search, Toolbar } from '@syncfusion/ej2-react-grids';




const Budget = () => {




  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const editing = { allowDeleting: true, allowEditing: true };


const navigate = useNavigate()

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
   try{
  
  
     const encryptedData = sessionStorage.getItem("userDataEnc");
     const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
     const decryptedData = AES.decrypt(encryptedData, encryptionKey);
     const decryptedString = decryptedData.toString(enc.Utf8);
     const parsedData = JSON.parse(decryptedString);
       setUserInfo(parsedData);
  
  
   }catch(error){
    navigate("/")
   }
  
  }, []);




  const [PortfolioList, setPortfolioList] = useState([])

  useEffect(()=>{
    fetch(apiServer+"ViewOurPortfolioProjects",{
      method:"POST",
      headers: {
        'UserId': userInfo.UserId,         
        'SessionId': userInfo.SessionId    
      },
    })
    .then(res => res.json())
    .then(data => setPortfolioList(data))
    .catch(error => console.error(error))
  },[userInfo])





  const [ProductId, setProductId] = useState("")
  const [PricingType, setPricingType] = useState("")
  const [Amount, setAmount] = useState(0.0)

  const [TransactionType, setTransactionType] = useState("");
  const [Narration, setNarration] = useState("");
  const [Credit, setCredit] = useState(0.0);
  const [Debit, setDebit] = useState(0.0);
  

  const handleConfigurePrice = async () => {

 

    Show.showLoading("Processing Data");
      try {
    
    const formData = new FormData()

    formData.append("Element", Narration)
    formData.append("Amount", Debit)
 
       
    formData.append("AdminId",userInfo.UserId)
   
        const response = await fetch(apiServer+"BudgetConfiguration", {
          method: "POST",
          headers: {
            'UserId': userInfo.UserId,         
            'SessionId': userInfo.SessionId    
          },
          body:formData
        });
    
        const data = await response.json();
     
    
        if (response.ok) {
          
          Show.hideLoading();
    
          Show.Success(data.message);
           window.location.reload()
          
        } else {
          Show.Attention(data.message);
        }
      } catch (error) {
    
        Show.Attention("An error has occured");
       
      }
    
    }





      const handleDeletePrice = async (Id) => {



        Show.showLoading("Processing Data");
          try {
        
        const formData = new FormData()

        formData.append("Id", Id)
        
      
           
        formData.append("AdminId",userInfo.UserId)
       
            const response = await fetch(apiServer+"DeleteBudgets", {
              method: "POST",
              headers: {
                'UserId': userInfo.UserId,         
                'SessionId': userInfo.SessionId    
              },
              body:formData
            });
        
            const data = await response.json();
         
        
            if (response.ok) {
              
              Show.hideLoading();
        
              Show.Success(data.message);
               window.location.reload()
              
            } else {
              Show.Attention(data.message);
            }
          } catch (error) {
        
            Show.Attention("An error has occured");
           
          }
        
        }





        const [PriceList, setPriceList] = useState([])

        useEffect(()=>{
          fetch(apiServer+"ViewBudgets",{
            method:"POST",
            headers: {
              'UserId': userInfo.UserId,         
              'SessionId': userInfo.SessionId    
            },
          })
          .then(res => res.json())
          .then(data => {
            // Process data to format Amount values to two decimal points
            const processedData = data.map(sale => ({
              ...sale,
              Amount: parseFloat(sale.Amount).toFixed(2) // Convert Amount to float and format to 2 decimal points
            }));
            setPriceList(processedData);
          })
          .catch(error => console.error(error));
        },[userInfo])
    


        const handleActionBegin = (args) => {
            if (args.requestType === 'save') {
              const updatedData = args.data;
              alert("Use The Forms To Edit")
            }
        
            if (args.requestType === 'delete') {
              const deletedData = args.data[0]; // assuming single delete
              handleConfirmation(deletedData.id)
            }
          };

          const handleConfirmation = (Id) => {
            Show.Confirm(
              "Do you want to delete this budget?", 
              () => handleDeletePrice(Id) 
            );
          };
          

         
        
        
        


  return (
    <div>
      <Header category="Financials Plus" title="Budget" />

      <div className="wwd-column">
        <div className="card" style={{ backgroundColor: localStorage.getItem("themeMode") === "Light" ? "#26293C" : "white" }}>
          <div className="sec-title" style={{ color: localStorage.getItem("colorMode"), padding: "2rem" }}>Add Element </div>

          <AdmitStudentRole>
      
       
          <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Enter Element</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setNarration(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Enter Amount (₵)</FormLable>
              <FormInputStudent
                type="number"
                required
                placeholder=""
                onChange={(e) => setDebit(e.target.value)}
              />
            </div>


          </AdmitStudentRole>

          <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={()=>{handleConfigurePrice()}}
            >Add
          </AdmitButton3>



        </div>
      </div>

      <div style={{ marginTop: "2rem", padding: "1rem" }}>
        <span>
          <u
            style={{
              color: localStorage.getItem("colorMode"),
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
          Budget List
          </u>
        </span>

        <GridComponent
           id="gridcomp"
      toolbar={['Search']}  // Add the search bar
 
          dataSource={PriceList}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
          actionBegin={handleActionBegin}
          style={{ backgroundColor: localStorage.getItem("colorMode") }}
        >
          <ColumnsDirective>
            {BudgetGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport, Search, Toolbar]} />

        </GridComponent>
      </div>
    </div>
  );
}

export default Budget;
