import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import Select from 'react-select';
import styled from 'styled-components';
import { AdmitButton3, AdmitStudentRole } from '../../data/Profile';
import { Header } from '../../components';
import Selector from '../../data/Selector';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { apiServer } from '../../data/Endpoint';
import { Show } from '../../data/Alerts';

const ModalView = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  max-width: 90vw;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 8px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  z-index: 10; /* Ensures it is on top */
  &:hover {
    color: red; /* Changes color on hover for visibility */
  }
`;
const FinancialReports = () => {
  const [userInfo, setUserInfo] = useState({});
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportData, setReportData] = useState(null);  // Update to handle initial state
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      const encryptedData = sessionStorage.getItem("userDataEnc");
      const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
      const decryptedData = AES.decrypt(encryptedData, encryptionKey);
      const decryptedString = decryptedData.toString(enc.Utf8);
      const parsedData = JSON.parse(decryptedString);
      setUserInfo(parsedData);
    } catch (error) {
      navigate("/");
    }
  }, [navigate]);

  const fetchReportData = async (reportType) => {
    try {
      const response = await fetch(apiServer + "generateComprehensiveFinancialReport", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
      });
      const data = await response.json();
      setReportData(data[selectedReport]);  // Ensure we set the correct part of the data
      setIsModalOpen(true);
    } catch (error) {
      console.error("Failed to fetch report data:", error);
    }
  };

  const handleViewReport = () => {
    if (selectedReport) {
      fetchReportData(selectedReport.ref);
    } else {
      Show.Attention("Please select a report type.");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderReportContent = () => {
    if (!reportData) return <p>No data available</p>;
  
    // Format function for currency
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-GH', {
        style: 'currency',
        currency: 'GHS',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
    };
  
    // Define financial ratio keys
    const financialRatios = [
      "Current Ratio",
      "Debt Ratio",
      "Return on Capital",
      "Profit Margin",
      "Asset Turnover Ratio",
      "Working Capital Efficiency Ratio",
      "Liquidity Ratio",
    ];
  
    return (
      <div>
        <div style={{ textAlign: "center", fontSize: "2rem" }}>{selectedReport} Report</div>
        {Object.entries(reportData).map(([key, value]) => (
          <div key={key} style={{ marginBottom: "1rem" }}>
            <h3>{key}</h3>
            <p>
              <strong>Value:</strong> 
              {financialRatios.includes(key) 
                ? value.value // No formatting for financial ratios
                : formatCurrency(parseFloat(value.value))} {/* Currency formatting for other values */}
            </p>
            <p><strong>Interpretation:</strong> {value.interpretation}</p>
          </div>
        ))}
      </div>
    );
  };
  
  
  return (
    <div>
      <Header category="Financials Plus" title="Financial Reports" />
      <div className="wwd-column">
        <div className="card" style={{ backgroundColor: localStorage.getItem("themeMode") === "Light" ? "#26293C" : "white" }}>
          <div className="sec-title" style={{ color: localStorage.getItem("colorMode"), padding: "2rem" }}>Comprehensive Report</div>

          <AdmitStudentRole>
            <Selector
              placeholder="Select Report Type"
              dataList={reportType}
              dataKey="ref"
              dataValue="ref"
              setMethod={(method) => setSelectedReport(method)}
            />
          </AdmitStudentRole>

          <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={handleViewReport}
          >
            View Report
          </AdmitButton3>
        </div>
      </div>

      <ModalView show={isModalOpen}>
        <ModalContent>
        
          <h1>{selectedReport?.ref}</h1>
          {renderReportContent()}

          <AdmitButton3 
           background={localStorage.getItem("colorMode")}
           color="white"
           border={localStorage.getItem("colorMode")}
           style={{ marginBottom: "1rem" }} 
          onClick={closeModal}>Close</AdmitButton3>

        </ModalContent>
      </ModalView>
    </div>
  );
};

export default FinancialReports;

export const reportType = [
  { id: 1, ref: "Profit and Loss Statement" },
  { id: 2, ref: "Financial Position" },
  { id: 3, ref: "Cash Flow Statement" },
  { id: 4, ref: "Financial Ratios" },
];
