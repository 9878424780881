import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import "./Section1.css";
import { Header } from '../../components';
import { Show } from '../../data/Alerts';
import { apiMedia, apiServer } from '../../data/Endpoint';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { FaFileArchive, FaTrashAlt } from 'react-icons/fa'; // Import the trash icon from react-icons

const ResourcesList = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body);

    return () => observer.disconnect();
  }, []);

  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    try {
      const encryptedData = sessionStorage.getItem("userDataEnc");
      const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
      const decryptedData = AES.decrypt(encryptedData, encryptionKey);
      const decryptedString = decryptedData.toString(enc.Utf8);
      const parsedData = JSON.parse(decryptedString);
      setUserInfo(parsedData);
    } catch (error) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.UserId && userInfo.SessionId) {
      const formData = new FormData();
      formData.append("AdminId", userInfo.UserId);

      fetch(apiServer + "GetAllResources", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      })
        .then(res => res.json())
        .then(data => {
          if (Array.isArray(data)) {
            setDataList(data);
          } else {
            setDataList([]);
          }
        })
        .catch(err => console.error(err));
    }
  }, [userInfo]);

  const handleDeleteAdmin = async (id) => {
    Show.showLoading("Processing Data");
    try {
      const formData = new FormData();
      formData.append("ResourceId", id);
      formData.append("AdminId", userInfo.UserId);

      const response = await fetch(apiServer + "DeleteResource", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        Show.hideLoading();
        Show.Success(data.message);
        window.location.reload();
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
      Show.Attention("An error has occurred");
    }
  };


  const DownloadImage = async (filename) => {
    Show.showLoading("Downloading..........");
    try {
      const formData = new FormData();
      formData.append("filename", filename);
      formData.append("AdminId", userInfo.UserId);
  
      const response = await fetch(apiServer + "DownloadImage", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });
  
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
  
        Show.hideLoading();
        Show.Success("Downloaded");
      } else {
        Show.Attention("Failed to download");
      }
    } catch (error) {
      Show.Attention(error.message);
    }
  };

  return (
    <div>
      <Header category="Resources Management" title="Resources List" />

      <div className="section">
        <div className="sec-row">
          {dataList.length > 0 ? (
            dataList.map((data, i) => {
              if (!data.Resource) {
                return (
                  <div className="sec-card" key={i}>
                    <p style={{ textAlign: "center", fontSize: "1rem" }}>No Resource Available</p>
                  </div>
                );
              }

              return (
                <div className="sec-card" key={i}>
                  {data.ResourceType === "PDF" && (
                    <iframe
                      src={apiMedia + data.Resource}
                      width="100%"
                      height="100%"
                      title={data.Title}
                      style={{ border: 'none', borderRadius: '1rem' }}
                    ></iframe>
                  )}
                  {data.ResourceType === "Video" && (
                    <video
                      src={apiMedia + data.Resource}
                      controls
                      width="100%"
                      height="100%"
                      style={{ borderRadius: '1rem' }}
                    />
                  )}
                  {data.ResourceType === "Audio" && (
                    <audio
                      src={apiMedia + data.Resource}
                      controls
                      style={{ width: "100%", borderRadius: '1rem' }}
                      onClick={()=>{
                        DownloadImage(data.Resource)
                         
                       }}
                    />
                  )}
                {data.ResourceType === "Picture" && (
                
                <img
                  src={apiMedia + data.Resource}
                  alt={data.Title}
                  width="100%"
                  height="100%"
                  style={{ cursor: 'pointer' }}
                  onClick={()=>{
                    DownloadImage(data.Resource)    
                  }}
                />

                )}

              {data.ResourceType === "Zip" && (
                    <div
                    onClick={()=>{
                      DownloadImage(data.Resource)                       
                     }} // Auto download the file
                      style={{ textAlign: 'center', cursor: 'pointer' }}
                    >
                      <FaFileArchive
                        style={{
                          fontSize: '4rem', // Set the icon size
                          color: '#007BFF', // Set the icon color
                          width: '100%', // Make the icon width similar to an image
                          height: '40vh', // Adjust height to maintain aspect ratio
                        }}
                       
                      />
                   
                    </div>
                  )}

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.5rem 1rem' }}>
                    <p style={{ textAlign: "center", fontSize: "1rem", margin: 0 }}>{data.Title}</p>
                    <FaTrashAlt 
                      onClick={() => handleDeleteAdmin(data.ResourceId)} 
                      style={{ cursor: 'pointer', color: 'red', fontSize: '1.5rem' }} 
                      title="Delete Resource"
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p style={{ textAlign: "center", fontSize: "1rem" }}>No resources available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResourcesList;
