import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import { AdmitButton3, AdmitStudentRole, FormInputStudent, FormLable } from '../../data/Profile';
import { Header } from '../../components';
import Selector from '../../data/Selector';
import { Show } from '../../data/Alerts';
import { apiServer } from '../../data/Endpoint';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';


const Resources = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: true };

  const [email, setEmail] = useState("");
  const [Category, setCategory] = useState("");
  const [ResourceType, setResourceType] = useState("");
  const [Title, setTitle] = useState("");
  const [UserId, setUserId] = useState("");
  const [Name, setName] = useState("");
  const [Resource, setResource] = useState("");
  const [AdminList, setAdminList] = useState([]);

  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    try {
      const encryptedData = sessionStorage.getItem("userDataEnc");
      const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
      const decryptedData = AES.decrypt(encryptedData, encryptionKey);
      const decryptedString = decryptedData.toString(enc.Utf8);
      const parsedData = JSON.parse(decryptedString);
      setUserInfo(parsedData);
    } catch (error) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("AdminId", userInfo.UserId);

    fetch(apiServer + "GetAllMeeting", {
      method: "POST",
      headers: {
        'UserId': userInfo.UserId,
        'SessionId': userInfo.SessionId
      },
      body: formData
    })
      .then(res => res.json())
      .then(data => setAdminList(data))
      .catch(err => console.error(err));
  }, [userInfo]);

  const handleCreateAdmin = async () => {
    Show.showLoading("Processing Data");
    try {
      const formData = new FormData();
      formData.append("Email", email);
      formData.append("Category", Category);
      formData.append("ResourceType", ResourceType);
      formData.append("Title", Title);
      formData.append("UserId", UserId);
      formData.append("Resource", Resource);
      formData.append("Name", Name);
      formData.append("AdminId", userInfo.UserId);

      const response = await fetch(apiServer + "CreateResources", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        Show.hideLoading();
        Show.Success(data.message);
        window.location.reload();
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
      Show.Attention("An error has occurred");
    }
  };

  const handleDeleteAdmin = async (id) => {
    Show.showLoading("Processing Data");
    try {
      const formData = new FormData();
      formData.append("UserId", id);
      formData.append("AdminId", userInfo.UserId);

      const response = await fetch(apiServer + "DeleteAdmin", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        Show.hideLoading();
        Show.Success(data.message);
        window.location.reload();
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
      Show.Attention("An error has occurred");
    }
  };

  const handleEdit = (id) => { };

  const handleActionBegin = (args) => {
    if (args.requestType === 'save') {
      const updatedData = args.data;
      handleEdit(updatedData);
    }

    if (args.requestType === 'delete') {
      const deletedData = args.data[0];
      handleDeleteAdmin(deletedData.UserId);
    }
  };

  const cate = [
    { id: 1, category: "Partners" },
    { id: 2, category: "Admin" },
    { id: 3, category: "Customer" },
    { id: 4, category: "Individual" },
    { id: 5, category: "Bulk" },
  ];

  const resourceType = [
    { id: 1, category: "Picture" },
    { id: 2, category: "Video" },
    { id: 3, category: "Audio" },
    { id: 4, category: "PDF" },
    { id: 5, category: "Zip" }, // Added Zip category
  ];
  
  const getFileAcceptType = () => {
    switch (ResourceType) {
      case "Picture":
        return ".jpg, .png, .jpeg, .ico";
      case "Video":
        return ".mp4, .mov";
      case "Audio":
        return ".wav, .mp3";
      case "PDF":
        return ".pdf";
      case "Zip": // Added case for Zip files
        return ".zip";
      default:
        return "";
    }
  };

  return (
    <div>
      <Header category="Resources Management" title="Resources" />

      <div className="wwd-row">
        <div className="card" style={{ backgroundColor: localStorage.getItem("themeMode") === "Light" ? "#26293C" : "white" }}>
          <div className="sec-title" style={{ color: localStorage.getItem("colorMode"), padding: "2rem" }}>Add Resources </div>

          <AdmitStudentRole>
            <Selector placeholder="Select Category" dataList={cate} dataKey="category" dataValue="category" setMethod={(method) => setCategory(method)} />
            <Selector placeholder="Select Resource Type" dataList={resourceType} dataKey="category" dataValue="category" setMethod={(method) => setResourceType(method)} />

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}> Title</FormLable>
              <FormInputStudent
                type="text"
                placeholder=""
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}> Email</FormLable>
              <FormInputStudent
                type="email"
                required
                placeholder=""
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>UserId</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Name</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}> Resource</FormLable>
              <FormInputStudent
                type="file"
                required
                placeholder=""
                accept={getFileAcceptType()}
                onChange={(e) => setResource(e.target.files[0])}
              />
            </div>
          </AdmitStudentRole>

          <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={handleCreateAdmin}
          >
            Send
          </AdmitButton3>
        </div>
      </div>


    </div>
  );
};

export default Resources;
