import React, { useEffect, useState } from 'react';
import "../Website/Website.css";
import Select from 'react-select';
import styled from 'styled-components';
import { AdmitButton3, AdmitStudentRole, FormInputStudent, FormLable } from '../../data/Profile';
import { colors } from '../../data/Colors';
import { contextMenuItems, continentList, countryList, customers, customersData, customersGrid, emailData, emailGrid, employeeData, employeeGrid, otherGrid, paymentData, paymentGrid, paymentMethod, paymentReference, products } from '../../data/champion';
import { GridComponent, ContextMenu, Edit, ExcelExport, Filter, Page, PdfExport, Resize, Sort, ColumnDirective, ColumnsDirective, Inject } from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import Selector from '../../data/Selector';
import { Show } from '../../data/Alerts';
import { apiServer } from '../../data/Endpoint';
import { AES, enc } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Search, Toolbar } from '@syncfusion/ej2-react-grids';
import { meetingList } from '../../data/Onboard';




const ScheduleMeeting = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      try {
        // Intentional empty block
      } catch (err) {
        if (err.message === 'ResizeObserver loop completed with undelivered notifications.') {
          console.warn('ResizeObserver loop error detected.');
        } else {
          throw err;
        }
      }
    });

    observer.observe(document.body); // Assuming observing the body for changes

    return () => observer.disconnect();
  }, []);

  const selectionsettings = { persistSelection: true };
  const toolbarOptions = ['Delete'];
  const editing = { allowDeleting: true, };


  const [name, setName] = useState("")
  const [link, setLink] = useState("")
  const [reason, setReason] = useState("")
  const [email, setEmail] = useState("")
  const [Category, setCategory] = useState("");
  const [time, setTime] = useState("")


  const [AdminList, setAdminList] = useState([])



  const navigate = useNavigate()

const [userInfo, setUserInfo] = useState({});

useEffect(() => {
 try{


   const encryptedData = sessionStorage.getItem("userDataEnc");
   const encryptionKey = '$2a$11$3lkLrAOuSzClGFmbuEAYJeueRET0ujZB2TkY9R/E/7J1Rr2u522CK';
   const decryptedData = AES.decrypt(encryptedData, encryptionKey);
   const decryptedString = decryptedData.toString(enc.Utf8);
   const parsedData = JSON.parse(decryptedString);
     setUserInfo(parsedData);


 }catch(error){
  navigate("/")
 }

}, []);


useEffect(()=>{

  const formData = new FormData();
  formData.append("AdminId",userInfo.UserId)

fetch(apiServer+"GetAllMeeting",{
  method: "POST",
      headers: {
        'UserId': userInfo.UserId,         
        'SessionId': userInfo.SessionId    
      },
      body:formData
})
.then(res=>res.json())
.then(data=>setAdminList(data))
.catch(err=>console.error(err))


},[userInfo])


const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
  
    // Extract parts of the date
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' }); // Get full month name
    const year = date.getFullYear();
  
    // Extract parts of the time
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12; // If hours is 0, set it to 12
  
    // Format the date and time
    return `${day}${getDaySuffix(day)} ${month}, ${year} at ${hours}:${minutes} ${ampm}`;
  };
  
  // Helper function to get the suffix for the day (e.g., "th", "st", "nd", "rd")
  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  
  const handleCreateAdmin = async () => {
    // Validate all fields

if(Category ==="Individual"){

  if (!email) {
    Show.Attention("Email is required");
    return; // Exit the function if email is missing
  }

  if (!link) {
    Show.Attention("Link is required");
    return; // Exit the function if link is missing
  }

  if (!name) {
    Show.Attention("Name is required");
    return; // Exit the function if name is missing
  }

  if (!reason) {
    Show.Attention("Reason is required");
    return; // Exit the function if reason is missing
  }

  if (!Category) {
    Show.Attention("Category is required");
    return; // Exit the function if Category is missing
  }

  if (!time) {
    Show.Attention("Time is required");
    return; // Exit the function if time is missing
  }

}

   
  
    Show.showLoading("Processing Data");
    try {
      const formData = new FormData();
      formData.append("Email", email);
      formData.append("Link", link);
      formData.append("Name", name);
      formData.append("Reason", reason);
      formData.append("Target", Category);
  
      // Convert the selected time to the desired format
      const formattedTime = formatDateTime(time);
      formData.append("Time", formattedTime);
  
      formData.append("AdminId", userInfo.UserId);
  
      const response = await fetch(apiServer + "ScheduleMeeting", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,
          'SessionId': userInfo.SessionId
        },
        body: formData
      });
  
      const data = await response.json();
  
      if (response.ok) {
        Show.hideLoading();
        Show.Success(data.message);
        window.location.reload();
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
      Show.Attention("An error has occurred: " + error.message);
    }
  };
  



const handleDeleteAdmin = async (id) => {

 

  Show.showLoading("Processing Data");
    try {
  
  const formData = new FormData()
  formData.append("UserId", id) 
  formData.append("AdminId",userInfo.UserId)

  
      const response = await fetch(apiServer+"DeleteAdmin", {
        method: "POST",
        headers: {
          'UserId': userInfo.UserId,         
          'SessionId': userInfo.SessionId    
        },
        body:formData
      });
  
      const data = await response.json();
   
  
      if (response.ok) {
        
        Show.hideLoading();
  
        Show.Success(data.message);
         window.location.reload()
        
      } else {
        Show.Attention(data.message);
      }
    } catch (error) {
  
      Show.Attention("An error has occured");
     
    }
  
  }







  const handleEdit = (id) => {
   
  };



  const handleActionBegin = (args) => {
    if (args.requestType === 'save') {
      const updatedData = args.data;
      handleEdit(updatedData);
    }

    if (args.requestType === 'delete') {
      const deletedData = args.data[0]; 
      handleDeleteAdmin(deletedData.UserId);
    }
  };

  const cate = [
    { id: 1, category: "Individual" },
    { id: 2, category: "Group" },
    { id: 3, category: "Partners" },

  ];


  return (
    <div>
      <Header category="APPS" title="Schedule Meeting" />

      <div className="wwd-row">

        <div className="card" style={{ backgroundColor: localStorage.getItem("themeMode") === "Light" ? "#26293C" : "white" }}>
          <div className="sec-title" style={{ color: localStorage.getItem("colorMode"), padding: "2rem" }}>Schedule Meeting </div>

          <AdmitStudentRole>

          <Selector placeholder="Select Target" dataList={cate} dataKey="category" dataValue="category" setMethod={(method) => setCategory(method)} />


            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}> Name</FormLable>
              <FormInputStudent
               type="text"

               placeholder=""
               onChange={(e) => setName(e.target.value)}
               
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}> Email</FormLable>
              <FormInputStudent
                type="email"
                required
                placeholder=""
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>



            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Link</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setLink(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}>Reason</FormLable>
              <FormInputStudent
                type="text"
                required
                placeholder=""
                onChange={(e) => setReason(e.target.value)}
              />
            </div>

            <div>
              <FormLable style={{ color: localStorage.getItem("colorMode") }}> Time</FormLable>
              <FormInputStudent
                type="datetime-local"
                required
                placeholder=""
                onChange={(e) => setTime(e.target.value)}
              />
            </div>





           
         
          </AdmitStudentRole>

          <AdmitButton3
            background={localStorage.getItem("colorMode")}
            color="white"
            border={localStorage.getItem("colorMode")}
            style={{ marginBottom: "1rem" }}
            onClick={()=>{ handleCreateAdmin()}}       
            >Send
          </AdmitButton3>

          

        </div>

     

      </div>

      <div style={{ marginTop: "2rem", padding: "1rem" }}>
        <span>
          <u
            style={{
              color: localStorage.getItem("colorMode"),
              textAlign: "center",
              fontSize: "1.5rem",
            }}
          >
          Meeting List
          </u>
        </span>

        <GridComponent
           id="gridcomp"
      toolbar={['Search']}  // Add the search bar
 
          dataSource={AdminList}
          enableHover={true}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
          actionBegin={handleActionBegin}
          style={{ backgroundColor: localStorage.getItem("colorMode") }}
        >
          <ColumnsDirective>
            {meetingList.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport, Search, Toolbar]} />

        </GridComponent>
      </div>





      
    </div>
  );
}

export default ScheduleMeeting;
